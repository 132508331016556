<template>
  <v-container>
    <div class="d-flex flex flex-column map-wrapper">
      <v-dialog
        v-model="dialog"
        :fullscreen="isMobile"
        persistent
        max-width="450px"
      >
        <v-card
          class="d-flex justify-center align-center pa-3"
          style="position: relative"
          height="470"
        >
          <v-col v-if="status === 'processing'" cols="9">
            <DvrLoader />
          </v-col>
          <div
            v-else
            class="d-flex flex-column justify-center align-center flex pa-6"
          >
            <img src="@/assets/images/payment-success.png" width="200" alt="" />
            <div class="text-h6 my-3">Payment Successful!</div>
            <div class="text-caption my-3 px-7 px-sm-3">
              Thank you for purchasing in {{ companyName }} store. We have sent
              you invoice with order number to your email {{ user.email }}.
              <div v-if="isAuthAndCapture">
                One of our agents will get back to you in the next 24 hours.
              </div>
              Enjoy your stay.
            </div>
            <v-btn class="mt-3 px-10" color="primary" @click="goToReservation">
              OK
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <div class="text-h4 pt-4 px-0 px-sm-3">Checkout</div>
      <v-row v-if="purchase" no-gutters>
        <v-col cols="12" md="6">
          <v-card class="mx-auto" max-width="800" elevation="0">
            <v-card-text class="px-0">
              <div v-if="isAuthAndCapture" class="py-2">
                We are notifying you that an authorization hold of
                <b>${{ totalAmountIncludingTax }}</b>
                will be placed on the credit card for
                <b>{{ checkoutItem.item_name }}</b> service. The money will be
                charged only after the service will be approved by our service
                provider. In case the service won't be available, the money will
                be automatically released back to your credit card.
              </div>
              <StripeElement
                v-if="purchase"
                :public-key="publicKey"
                :checkout-item="checkoutItem"
                :client-secret="purchase.payment_intent.client_secret"
                button-text="Confirm & pay"
                @payment-success="paymentSuccess"
                @payment-processing="paymentProcessing"
                @payment-failed="paymentFailed"
              >
                <StoreCheckoutSummary
                  v-if="isMobileOrIPad && purchase"
                  :item="checkoutItem"
                  :reservation="purchase.reservation"
                />
                <CheckoutSummary
                  v-if="isMobileOrIPad"
                  :item="checkoutItem"
                  :tax="tax"
                  :total="totalAmountIncludingTax"
                />
              </StripeElement>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="!isMobileOrIPad" cols="12" md="6">
          <div class="d-flex justify-center">
            <v-col cols="12" md="11" lg="8" class="pa-7 pa-md-0">
              <StoreCheckoutSummary
                v-if="!isMobileOrIPad && purchase"
                :reservation="purchase.reservation"
                :item="checkoutItem"
              />
              <div class="px-0 px-sm-4">
                <div
                  v-if="tax"
                  class="d-flex text-body-1 mt-5 justify-space-between"
                >
                  <div>Tax({{ storeTax }}%)</div>
                  <div>{{ toCurrency(tax, { currency }) }}</div>
                </div>
                <v-divider class="my-5" />
                <div class="py-3">
                  <div class="d-flex text-h6 justify-space-between">
                    <div>Total</div>
                    <div>
                      {{ toCurrency(totalAmountIncludingTax, { currency }) }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import StripeElement from 'components/stripe/StripeCustomElement'
import CheckoutSummary from 'components/store/checkout-summary'
import StoreCheckoutSummary from 'components/store/store-checkout-summary'
import deviceMixin from 'components/mixins/deviceMixin'
import commonFunctions from 'components/mixins/common-functions'
import DvrLoader from '@/assets/icons/dvr-loader'
import get from 'lodash/fp/get'
import { mapState } from 'vuex'
import { HOTJAR_EVENTS } from '@/lib/analytics/hotjar'
import { mapActions } from 'vuex'

export default {
  name: 'StoreCheckout',
  components: {
    DvrLoader,
    StoreCheckoutSummary,
    CheckoutSummary,
    StripeElement,
  },
  mixins: [deviceMixin, commonFunctions],
  data() {
    return {
      dialog: false,
      status: null,
      user: {},
      purchase: null,
      publicKey: null,
    }
  },
  computed: {
    ...mapState({
      reservationsFetched: state => state.tourist.reservationsFetched,
      storeItems: state => state.cart.storeItems,
    }),
    checkoutItem() {
      if (!this.purchase) {
        return {}
      }
      const storeItem = this.storeItems[this.purchase.store_item_id] || {}
      const reservation =
        this.$store.state.tourist.reservations.find(
          r => r.id === this.purchase.reservation_id
        ) || {}
      return {
        ...this.purchase,
        item_name: storeItem.name,
        item_description: storeItem.description,
        item_image: storeItem.image,
        reservation,
      }
    },
    isAuthAndCapture() {
      return get('payment_intent.capture_method', this.purchase) === 'manual'
    },
    totalAmount() {
      return this.purchase.amount.toFixed(2)
    },
    totalAmountIncludingTax() {
      return get('payment_intent.amount', this.purchase) / 100
    },
    tax() {
      return this.totalAmountIncludingTax - this.totalAmount
    },
    relevantReservation() {
      return this.reservations.find(r => r.id === this.purchase.reservation_id)
    },
    reservations() {
      return this.$store.getters.currentlyStayReservations.concat(
        this.$store.getters.upcomingReservations
      )
    },
    currency() {
      return this.purchase.reservation.listing.get_currency
    },
  },
  async mounted() {
    const { data: purchase } = await this.$store.dispatch('getPurchase', {
      intent_id: this.$route.params.id,
    })
    this.purchase = purchase
    this.publicKey = this.purchase.stripe_public_key
    if (!purchase.payment_intent) {
      this.$router.push({
        name: 'store-tabs',
      })
    }
  },
  methods: {
    ...mapActions(['logAction']),

    paymentSuccess({ user }) {
      this.logAction({
        eventType: HOTJAR_EVENTS.PAYMENT_SUCCESSFUL,
        storeItemId: this.checkoutItem.store_item_id,
      })
      this.dialog = true
      setTimeout(() => {
        this.status = 'success'
        this.user = user
      }, 3000)
    },
    paymentProcessing() {
      this.logAction({
        eventType: HOTJAR_EVENTS.PAYMENT_ATTEMPT,
        storeItemId: this.checkoutItem.store_item_id,
      })
      this.status = 'processing'
      this.dialog = true
    },
    paymentFailed() {
      this.status = 'failed'
      this.dialog = false
    },
    goToReservation() {
      const isExternal = this.$route.query.externalCheckout
      if (isExternal) {
        // window.location.href = URLS.DESIGNEDVR
      } else {
        this.$router.push({
          name: 'reservation-info',
          params: {
            id: this.relevantReservation.confirmation_code,
          },
        })
      }
    },
  },
}
</script>

<style scoped></style>
