<template>
  <div id="chekin-container"></div>
</template>

<script>
import isEmpty from 'lodash/fp/isEmpty'
import { CHEKIN_SDK_CDN } from '@/consts'

export default {
  name: 'ChekinGuestSdk',
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    reservationId: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    preCheckIn: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const script = document.createElement('script')
    script.src = (process.env.NODE_ENV === 'production') ? CHEKIN_SDK_CDN.PRODUCTION : CHEKIN_SDK_CDN.STAGING
    script.onload = () => {
      const api = new window.ChekinPro()
      const defaultLanguage = this.$i18n.locale

      const initializeApi = () => {
        if (document.getElementById('chekin-container')) {
          api.initialize({
            apiKey: this.apiKey,
            reservationId: this.reservationId,
            defaultLanguage: defaultLanguage,
            canEditReservationDetails: false,
            enableGuestsRemoval: true,
            onGuestRegistered: this.onGuestRegistered,
            onAllGuestsRegistered: onAllGuestsRegistered =>
              console.log('All guests registered!', onAllGuestsRegistered),
            onReservationFound: reservationFound =>
              console.log('reservationFound!', reservationFound),
          })
          api.renderApp({ targetNode: 'chekin-container' })
        } else {
          setTimeout(initializeApi, 100)
        }
      }

      initializeApi()
    }
    document.head.appendChild(script)
  },
  methods: {
    onGuestRegistered(data) {
      const payload = this.mapGuestObjectToRecord(data)

      if (!isEmpty(payload)) {
        this.$store.dispatch('saveTouristPreCheckIn', {
          ...this.preCheckIn,
          step: this.step,
          ...payload,
        })
      }
    },
    mapGuestObjectToRecord(guestObject) {
      const phone = guestObject.phone
      return {
        client_info: {
          email: guestObject.email,
          phone_number: phone && `${phone.code}${phone.number}`,
          birthday: guestObject.birth_date,
        },
        terms_signature_image: guestObject.signature,
        selfie_pic: guestObject.biomatch_selfie,
      }
    },
  },
}
</script>

<style scoped>
#chekin-container {
  width: 100%;
  height: 110vh;
}
</style>
