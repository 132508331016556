<template>
  <v-card
    :width="isMobileOrIPad ? 320 : 400"
    :height="isMobileOrIPad ? 300 : 420"
    class="d-flex overflow-hidden flex-column reservation-thumbnail pointer"
    :ripple="false"
    :link="false"
    @click="onTripClick"
  >
    <v-img
      :height="isMobileOrIPad ? 130 : 250"
      width="100%"
      :src="reservation.listing.picture"
    />
    <div
      class="text-body-2 secondary--text py-3 px-4 d-flex align-center justify-space-between"
    >
      <div>
        <span
          >{{
            formatDate(
              reservation.check_in,
              'MMM Do',
              reservation.listing.timezone
            )
          }}
        </span>
        <span> - </span>
        <span
          >{{
            formatDate(
              reservation.check_out,
              'MMM Do',
              reservation.listing.timezone
            )
          }}
        </span>
      </div>
      <div class="d-flex align-center">
        <v-icon small class="mr-1"> fas fa-map-marker-alt </v-icon>
        {{ reservation.listing.city_name }}
      </div>
    </div>
    <div class="px-4 d-flex justify-space-between align-center">
      <div class="text-subtitle-1">{{ title }}</div>
      <v-chip light :color="statusColor.color" x-small>{{
        statusColor.text
      }}</v-chip>
    </div>
    <v-spacer />
    <v-divider />
    <v-btn v-if="shouldPayAccommodation" height="40" color="error" class="pa-3">
      {{ $t('PayYourBalanceNow') }}
    </v-btn>
    <v-btn v-else height="40" text small color="black" class="pa-3">
      {{ $t('ShowTripInfo') }}
    </v-btn>
  </v-card>
</template>

<script>
import CommonFunctions from '@/components/mixins/common-functions'
import deviceMixin from '@/components/mixins/deviceMixin'
import moment from 'moment'
export default {
  name: 'TripThumbnail',
  mixins: [CommonFunctions, deviceMixin],
  props: {
    reservation: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    acceptAccommodation() {
      return (
        this.reservation.checkin_info &&
        this.reservation.checkin_info.accept_portal_payments
      )
    },
    shouldPayAccommodation() {
      return (
        this.acceptAccommodation &&
        this.reservation.is_merchant &&
        this.reservation.balance_due > 0 &&
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'accommodation' &&
            intent.status === 'requires_source'
        )
      )
    },
    statusColor() {
      const check_in = moment(this.reservation.check_in)
      const check_out = moment(this.reservation.check_out)
      const now = moment()
      if (check_in.isBefore(now) && check_out.isAfter(now)) {
        return { color: 'success', text: this.$t('current') }
      } else if (check_in.isAfter(now)) {
        return { color: 'pending', text: this.$t('upcoming') }
      }
      return { color: 'error', text: this.$t('past') }
    },
    title() {
      const { nickname, hotel_name } = this.reservation.listing
      const { checkin_info } = this.reservation
      const hide_hotel_units = checkin_info && checkin_info.hide_hotel_units
      return (hide_hotel_units && hotel_name) || nickname
    },
  },
  methods: {
    onTripClick() {
      const { confirmation_code } = this.reservation
      const confirmationCode = encodeURIComponent(confirmation_code)

      if (this.disabled) {
        this.$router.push(`/tourist/trips/${confirmationCode}/info`)
        return
      }
      if (this.shouldPayAccommodation) {
        const intent = this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'accommodation' &&
            (intent.status === 'requires_source' ||
              intent.status === 'requires_payment_method')
        )
        this.$router.push(
          `/tourist/trips/${confirmationCode}/checkout/${intent.payment_intent_id}`
        )
      } else if (
        this.reservation.pre_check_in &&
        this.reservation.pre_check_in.status === 'Done'
      ) {
        this.$router.push(`/tourist/trips/${confirmationCode}/info`)
      } else {
        this.$router.push(`/tourist/trips/${confirmationCode}`)
      }
    },
  },
}
</script>

<style scoped>
.reservation-thumbnail {
  cursor: pointer;
}
.v-card--link:focus:before {
  opacity: 0;
}
</style>
