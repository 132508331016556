import axios from 'axios'
import keyBy from 'lodash/fp/keyBy'
import isEmpty from 'lodash/fp/isEmpty'
export default {
  state: {
    currentPurchase: null,
    purchaseFetched: false,
    storeItems: {},
  },
  mutations: {
    updateCurrentPurchase(state, data) {
      if (!state.purchaseFetched) {
        state.purchaseFetched = true
      }
      state.currentPurchase = data
    },
    updateTouristStoreItems(state, data) {
      state.storeItems = keyBy('id', data)
    },
  },
  actions: {
    async getAllItems({ commit }) {
      try {
        const { data } = await axios.get(`/api/tourist/store-items`)
        commit('updateTouristStoreItems', data)
      } catch (err) {
        console.error(err)
      }
    },
    async getPurchase({ commit }, params) {
      try {
        const { data } = await axios.get(`/api/store/purchase`, { params })
        commit('updateCurrentPurchase', data)
        return { data }
      } catch (err) {
        console.error(err)
      }
    },
    async createAuthorizationRequest(_store, { reservationId, ...rest }) {
      try {
        const {
          data,
        } = await axios.post(
          `/api/tourist/reservations/${reservationId}/store-items/authorize`,
          { ...rest }
        )
        return data
      } catch (err) {
        console.error(err)
      }
    },
    async placeOrder(_store, { itemId, reservationId, options }) {
      try {
        const {
          data,
        } = await axios.post(
          `/api/tourist/reservations/${reservationId}/store-items/add`,
          { item_id: itemId, options }
        )
        return data
      } catch (err) {
        console.error(err)
      }
    },
  },
  getters: {
    purchasedItems(state, getters, rootState, rootGetters) {
      if (!rootGetters.currentReservation || isEmpty(state.storeItems)) {
        return []
      }
      return rootGetters.currentReservation.purchased_items.map(purchase => {
        const storeItem = state.storeItems[purchase.store_item_id] || {}
        const reservation =
          rootState.tourist.reservations.find(
            r => r.id === purchase.reservation_id
          ) || {}
        return {
          ...purchase,
          item_name: storeItem.name,
          item_description: storeItem.description,
          item_image: storeItem.image,
          reservation,
        }
      })
    },
  },
}
