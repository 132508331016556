<template>
  <div v-if="loading" class="d-flex align-center fill-height justify-center">
    <DvrLoader />
  </div>
  <div v-else class="d-flex flex-column flex py-3 px-sm-4 pre-check-in-wrapper">
    <v-overlay
      v-show="shouldPayBalance"
      color="white"
      light
      absolute
      :opacity="0.9"
    >
      <v-card
        elevation="0"
        max-width="550"
        min-width="300"
        class="d-flex transparent"
      >
        <v-row class="justify-center align-center flex-column pa-6">
          <v-icon color="warning" size="150" class="pa-2">
            fas fa-credit-card
          </v-icon>
          <div class="pb-6 text-center black--text text-subtitle-2">
            {{ $t('CompleteBookingPayment') }}
          </div>
          <div>
            <v-btn
              :to="`/tourist/trips/${reservation.confirmation_code}/checkout/${reservationIntent.payment_intent_id}`"
              link
              color="warning"
            >
              {{ $t('ProceedToCheckout') }}
            </v-btn>
          </div>
        </v-row>
      </v-card>
    </v-overlay>
    <v-progress-circular
      v-if="loading"
      :size="20"
      class="ml-2"
      indeterminate
      color="primary"
    />
    <v-stepper
      v-else-if="(preCheckIn || shouldPayBalance) && reservation.listing.id"
      v-model="step"
      class="fill-height d-flex flex-column"
    >
      <v-row
        v-if="step <= steps.length"
        class="justify-center flex-grow-0 flex-shrink-0"
        no-gutters
      >
        <v-col
          v-if="$route.params.message"
          cols="12"
          class="text-subtitle-2 text-center warning--text"
        >
          {{ $route.params.message }}
        </v-col>
        <v-col cols="10" sm="8" md="4">
          <v-stepper-header>
            <template v-for="(item, ind) in steps">
              <v-stepper-step
                :key="`$step${ind}`"
                color="darkpurle"
                :complete="step > ind + 1"
                :step="ind + 1"
              />
              <v-divider v-if="ind < steps.length - 1" :key="`div${ind}`" />
            </template>
          </v-stepper-header>
        </v-col>
      </v-row>
      <v-container class="flex-grow-1 d-flex flex-column pt-0">
        <v-row class="justify-center">
          <v-col cols="12" class="d-flex" elevation="0">
            <v-stepper-items v-if="preCheckIn" class="flex">
              <component
                :is="comps.component"
                v-for="(comps, ind) in steps"
                :ref="comps.component"
                :key="comps.component"
                :step="ind + 1"
                :pre-check-in="preCheckIn"
                @step-back="stepBack"
                @step-forward="stepForward"
              />
            </v-stepper-items>
          </v-col>
        </v-row>
        <v-divider />
        <div class="py-6 d-flex">
          <v-btn
            v-if="step > 1 && step <= steps.length"
            elevation="0"
            large
            color="secondary.lighten1"
            @click="stepBack"
          >
            <v-icon class="pe-3">
              {{
                $vuetify.rtl ? '$long_right_arrow' : '$long_left_arrow'
              }} </v-icon
            >{{ $t('Back') }}
          </v-btn>
          <v-spacer />
          <pre-check-in-complete
            v-if="step == steps.length"
            :next-step="nextStep"
            :reservation="reservation"
          />
          <v-btn v-else elevation="0" large color="primary" @click="nextStep">
            {{ $t(steps[step - 1].nextButton) }}
            <v-icon v-if="step < steps.length" class="ps-3">
              {{ $vuetify.rtl ? '$long_left_arrow' : '$long_right_arrow' }}
            </v-icon>
          </v-btn>
        </div>
      </v-container>
    </v-stepper>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common-functions.js'
import GuestsInfo from '@/components/newsteps/guests-info'
import StoreStep from '@/components/newsteps/store-step'
import SpecialRequests from '@/components/newsteps/special-requests'
import PreCheckInComplete from '@/components/trips/pre-check-in-complete'
import PersonalInfo from '@/components/newsteps/personal-info'
import DvrLoader from '@/assets/icons/dvr-loader'
import TouristStoreView from '@/components/store/tourist-store-view'
import isEmpty from 'lodash/fp/isEmpty'
export default {
  name: 'TripPreCheckIn',
  components: {
    TouristStoreView,
    DvrLoader,
    PersonalInfo,
    SpecialRequests,
    GuestsInfo,
    StoreStep,
    PreCheckInComplete,
  },
  mixins: [CommonFunctions],
  data() {
    return {
      step: 1,
      isSubmitting: false,
      stepsItems: [
        {
          component: 'GuestsInfo',
          nextButton: this.$t('Next'),
          show: true,
        },
        {
          component: 'SpecialRequests',
          nextButton: this.$t('Next'),
          show: !this.isResort,
        },
        {
          component: 'PersonalInfo',
          nextButton: this.$t('Submit'),
          show: true,
        },
        {
          component: 'StoreStep',
          nextButton: this.$t('Not now'),
          show: this.storeEnabled && !this.isResort,
        },
      ],
    }
  },
  computed: {
    steps() {
      return this.stepsItems.filter(s => s.show)
    },
    isResort() {
      return Boolean(this.reservation.listing.is_resort)
    },
    preCheckIn() {
      return this.$store.state.tourist.touristPreCheckIn
    },
    shouldPayBalance() {
      return this.$store.state.tourist.hasRemainingBalance
    },
    items() {
      return this.$store.getters.reservationItems
    },
    deposit() {
      return this.reservation.payment_intents.find(
        intent =>
          intent.metadata.type === 'deposit' &&
          (intent.status === 'requires_source' ||
            intent.status === 'requires_payment_method')
      )
    },
    isLastStep() {
      return this.step === this.steps.length
    },
    reservationIntent() {
      return (
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'accommodation' &&
            (intent.status === 'requires_source' ||
              intent.status === 'requires_payment_method')
        ) || {}
      )
    },
    reservation() {
      return (
        this.$store.getters.currentReservation || {
          listing: {},
          payment_intents: [],
        }
      )
    },
    loading() {
      return this.$store.getters.isTouristLoading
    },
  },
  async mounted() {
    this.$store.dispatch('getReservationStoreItems', this.$route.params.id)

    await this.$store.dispatch(
      'getTouristPreCheckIn',
      this.$router.currentRoute.params.id
    )
    if (this.$route.params.step) {
      this.step = parseInt(this.$route.params.step)
    } else {
      this.step =
        this.preCheckIn.step > this.steps.length || this.preCheckIn.step === 0
          ? 1
          : this.preCheckIn.step
    }
  },
  methods: {
    nextStep(stepForward) {
      const component = this.$refs[this.steps[this.step - 1].component][0]
      if (component.validate) {
        return component.validate(stepForward)
      } else {
        this.stepForward()
      }
    },
    async stepForward(payload = {}) {
      const currentStep = this.step
      const isLastStep = this.isLastStep
      if (!isLastStep) {
        this.step++
        await this.$store.dispatch('saveTouristPreCheckIn', {
          ...this.preCheckIn,
          step: currentStep,
          ...payload,
        })
      } else {
        if (!isEmpty(payload)) {
          await this.$store.dispatch('saveTouristPreCheckIn', {
            ...this.preCheckIn,
            step: currentStep,
            ...payload,
          })
        }
        await this.$store.dispatch('getTouristTripInfo', this.$route.params.id)
        this.$router.push({ name: 'reservation-info' })
      }
    },
    stepBack() {
      this.step--
    },
  },
}
</script>

<style scoped>
.pre-check-in-wrapper {
  position: relative;
}
.pre-check-in-wrapper >>> .v-stepper__step__step {
  margin: 0 8px;
}
@media (min-width: 1904px) {
  .store-container {
    max-width: 1400px;
  }
}
</style>
