<template>
  <div :class="[isMobile ? 'signature-box-mobile' : 'signature-box']">
    <vue-signature-pad
      ref="signaturePad"
      :height="isMobile ? '110px' : '65px'"
      :options="{
        onBegin: () => {
          $refs.signaturePad.resizeCanvas()
        },
      }"
    />
    <v-divider class="mx-2" />
    <v-progress-linear v-if="loading" indeterminate color="primary" />
    <div class="ma-1">
      <v-btn
        text
        small
        color="success"
        class="font-weight-bold"
        @click="saveSignature"
        >{{ signed ? $t('Update') : $t('Save') }}</v-btn
      >
      <v-btn
        text
        small
        color="black"
        @click="$refs.signaturePad.clearSignature()"
        >{{ $t('Clear') }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import deviceMixin from 'components/mixins/deviceMixin'

export default {
  name: 'SignaturePad',
  mixins: [deviceMixin],
  data() {
    return {
      loading: false,
      signed: false,
    }
  },
  methods: {
    async saveSignature() {
      this.loading = true
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (isEmpty) return
      const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/do4tedxg6/upload'
      const formData = new FormData()
      formData.append('upload_preset', 'ie2mgrcv')
      formData.append('file', data)
      const config = {
        withCredentials: false,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const res = await axios.post(cloudinaryUrl, formData, config)
      this.loading = false
      this.signed = true
      this.$emit('signature', res.data.secure_url)
    },
  },
}
</script>

<style scoped>
.signature-box {
  border: solid 1px #000;
  width: 250px;
  height: 100px;
}
.signature-box-mobile {
  border: solid 1px #000;
  width: 100%;
  height: 150px;
}
</style>
