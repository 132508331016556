<template>
  <v-stepper-content :step="step" class="pa-0 fill-height">
    <v-container v-if="!hasChekinVerification" fluid class="pa-0">
      <div class="text-h6 font-weight-medium">{{ $t('ContactInfo') }}</div>
      <v-form ref="form" class="flex d-flex flex-column">
        <v-row no-gutters class="my-4">
          <v-col cols="12" sm="6" class="pr-sm-6">
            <v-text-field
              v-model="clientInfo.email"
              prepend-inner-icon="person"
              :rules="emailRules"
              outlined
              name="email"
              required
              label="Email"
              type="text"
            />
          </v-col>
          <v-col cols="12" sm="6" class="pr-sm-6">
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              required
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="clientInfo.birthday"
                  persistent-hint
                  outlined
                  v-bind="attrs"
                  :label="$t('Date of birth')"
                  :rules="[required]"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="clientInfo.birthday"
                :max="maxDate"
                min="1940-01-01"
                @change="save"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" class="pr-sm-6">
            <phone-input
              :value.sync="phone"
              :error.sync="showPhoneError"
              :initial-phone="clientInfo.phone_number"
            />
          </v-col>

          <v-col cols="12">
            <v-divider class="my-4" />
          </v-col>

          <template v-if="hasSuperhogVerification">
            <v-col cols="12" sm="6" class="pr-sm-6">
              <v-text-field
                v-model="clientInfo.address.address_line1"
                prepend-inner-icon="home"
                outlined
                name="address"
                :rules="hasSuperhogVerification ? [required] : []"
                label="Address"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pr-sm-6">
              <v-text-field
                v-model="clientInfo.address.town"
                prepend-inner-icon="location_city"
                outlined
                name="town"
                :rules="hasSuperhogVerification ? [required] : []"
                :label="$t('Town')"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pr-sm-6">
              <v-text-field
                v-model="clientInfo.address.county"
                prepend-inner-icon="location_city"
                outlined
                name="county"
                :rules="hasSuperhogVerification ? [required] : []"
                :label="$t('County')"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pr-sm-6">
              <v-text-field
                v-model="clientInfo.address.zip_code"
                prepend-inner-icon="mail"
                outlined
                name="zip_code"
                :rules="hasSuperhogVerification ? [required] : []"
                :label="$t('Zip code')"
                type="text"
              />
            </v-col>
          </template>

          <v-col v-if="showMembership && !isResort" cols="12">
            <v-checkbox
              v-model="requestRemember"
              class="text-start"
              :label="
                customMembershipTitle ||
                $t('Join the club – unlock special rates and offers now!')
              "
              :hint="
                customMembershipDescription ||
                $t(
                  `By joining the Vacation Club, you're stepping into a world of exclusive perks tailored for discerning travelers. This membership grants you access to special discounts, personalized promotions, and priority booking options at our premium vacation rentals.`
                )
              "
              persistent-hint
            />
          </v-col>
        </v-row>
        <div v-if="showSelfieId">
          <v-row class="align-center">
            <v-col cols="12">
              <div class="text-h6 font-weight-medium my-3">
                {{ $t('Upload ID') }}
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="!hasSuperhogVerification"
            class="d-flex justify-center align-center"
            height="280px"
          >
            <v-col cols="12" sm="12" md="8">
              <profile-upload
                :title="$t('Selfie with your ID')"
                :value.sync="documentId"
              >
                <template #instructions>
                  <v-img
                    class="mx-4 flex"
                    src="@/assets/images/selfie.png"
                    alt="Description"
                    contain
                    max-width="400"
                    min-width="300"
                  />
                </template>
              </profile-upload>
            </v-col>
          </v-row>
          <v-row
            v-else
            class="d-flex justify-center align-end grey lighten-5"
            height="280px"
          >
            <profile-upload
              :title="$t('Please upload a clear picture of your ID')"
              :value.sync="documentId"
            />
            <profile-upload
              :title="$t('Please upload a clear selfie')"
              :value.sync="selfiePic"
            />
          </v-row>

          <div
            v-if="
              showRequireVerificationDocsError && (!documentId || !selfiePic)
            "
            class="error--text text-caption"
          >
            {{ $t('You must upload ID document and Selfie') }}
          </div>

          <div
            v-if="showUploadError && !documentId"
            class="error--text text-caption"
          >
            {{ $t('You must upload ID document') }}
          </div>
          <div class="text-caption flex red-health mt-1">
            {{ $t('DocumentationRequirement') }}
          </div>
        </div>
        <v-row v-if="requestedDocuments.length" class="justify-center">
          <v-col cols="12">
            <div class="text-h6 font-weight-medium mt-6">
              {{ $t('Additional documents') }}
            </div>
          </v-col>
          <v-col v-for="doc in requestedDocuments" :key="doc.id" cols="12">
            <v-col cols="12">
              <div class="text-h6 font-weight-medium my-1">
                {{ doc.title }}
                <span v-if="docExists(doc.id)"
                  ><v-icon color="success">mdi-check</v-icon></span
                >
              </div>
              <div class="text-caption flex red-health mt-1">
                {{ doc.description }}
              </div>
            </v-col>
            <v-col cols="12" sm="12" :md="doc.type === 'B2 slip' ? 8 : 4">
              <profile-upload
                :title="$t('Upload file')"
                :value.sync="doc.url"
                @on-upload="
                  uploadedDocs.push({
                    type: doc.type,
                    url: $event,
                    id: doc.id,
                  })
                "
              >
                <template v-if="doc.type === 'B2 slip'" #instructions>
                  <v-img
                    class="mx-4 flex"
                    src="@/assets/images/b2slip.png"
                    alt="Description"
                    contain
                    max-width="400"
                    min-width="300"
                  />
                </template>
              </profile-upload>
            </v-col>
          </v-col>
          <v-col v-if="showMissingDocsError" cols="12">
            <div class="error--text text-caption mt-1">
              {{ $t('You must upload all required documents') }}
            </div>
          </v-col>
        </v-row>
      </v-form>

      <v-divider class="my-4" />
      <div class="text-h6 font-weight-medium">{{ $t('Policy') }}</div>
    </v-container>

    <div v-else-if="checkInVerificationIdentifier && chekinGuestKey" class="text-h6 font-weight-medium">
      <chekin-guest-sdk
        :api-key="chekinGuestKey"
        :reservation-id="checkInVerificationIdentifier"
        :step="step"
        :pre-check-in="preCheckIn"
      />
    </div>
    <div style="height: 100vh;" v-else>
      <v-overlay
        color="white"
        light
        absolute
        :opacity="0.9"
      >
        <v-progress-circular color="primary" indeterminate size="50" />
      </v-overlay>
    </div>

    <div class="flex d-flex">
      <div class="w-full">
        {{
          requireSignature
            ? $t(
                'By signing, you agree that you have reviewed and agreed to the'
              )
            : $t(
                'By submitting , you agree that you have reviewed and agreed to the'
              )
        }}

        <ReadTermsBtn :text="termsOfUse || preCheckIn.generated_sign_doc">
          <template v-slot:activator="slotData">
            <a
              class="px-1 black--text text-decoration-underline font-weight-medium"
              v-on="slotData.on"
              >{{ $t('Terms') }}</a
            >
          </template>
        </ReadTermsBtn>
        <div v-if="requireSignature">
          <div
            v-if="preCheckIn.terms_signature_image"
            class="font-weight-bold mt-4"
          >
            <v-icon color="success" class="me-1">mdi-check</v-icon>
            {{ $t('Signed') }}
          </div>
          <div v-else class="mt-4">
            <div class="text--secondary text-body-2">
              {{ $t('Please Sign in the box below') }}
            </div>
            <signature-pad @signature="updateSignature" />
            <div v-show="showTermsError" class="text-caption error--text mt-1">
              {{ $t('Please review and sign the terms and conditions') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-stepper-content>
</template>

<script>
import moment from 'moment'
import formRules from '@/components/mixins/form-rules-mixin'
import ReadTermsBtn from '@/components/common/read-terms-btn'
import ProfileUpload from '@/components/common/profile-upload'
import DeviceMixin from 'components/mixins/deviceMixin'
import PhoneInput from '@/components/common/phone-input.vue'
import SignaturePad from '@/components/common/signature-pad.vue'
import { isEmpty } from 'lodash'
import ChekinGuestSdk from '@/components/common/chekin-guest-sdk.vue'

export default {
  name: 'PersonalInfo',
  components: {
    ProfileUpload,
    ReadTermsBtn,
    PhoneInput,
    SignaturePad,
    ChekinGuestSdk,
  },
  mixins: [formRules, DeviceMixin],
  props: ['preCheckIn', 'isResort', 'step'],
  data() {
    return {
      clientInfo: { address: {}, ...this.preCheckIn.client_info },
      checkinInfo: this.preCheckIn.checkin_info || {},
      dateMenu: false,
      documentId: this.preCheckIn.id_pic,
      selfiePic: this.preCheckIn.selfie_pic,
      additionalDocumentId: this.preCheckIn.additional_doc,
      showUploadError: false,
      showPhoneError: false,
      showRequireVerificationDocsError: false,
      showMissingDocsError: false,
      requestRemember: this.preCheckIn.request_to_memeber,
      phone: null,
      termsSignature: null,
      showTermsError: false,
      uploadedDocs: [...this.preCheckIn.additional_documents],
      termsOfUse: this.preCheckIn.terms_of_use,
    }
  },
  computed: {
    hasSuperhogVerification() {
      return this.superhogEnabled
    },
    hasChekinVerification() {
      return !!this.chekinGuestKey
    },
    checkInVerificationIdentifier() {
      return this.preCheckIn.chekin_verification_identifier
    },
    maxDate() {
      return moment().subtract(18, 'years').format('YYYY-MM-DD')
    },
    user() {
      return this.$store.getters.user
    },
    requireSignature() {
      return this.checkinInfo && this.checkinInfo.require_signature
    },
    customMembershipTitle() {
      return (
        this.checkinInfo &&
        !isEmpty(this.checkinInfo.membership_title) &&
        this.checkinInfo.membership_title
      )
    },
    customMembershipDescription() {
      return (
        this.checkinInfo &&
        !isEmpty(this.checkinInfo.membership_description) &&
        this.checkinInfo.membership_description
      )
    },
    requestedDocuments() {
      return this.preCheckIn.additional_docs.map(d => {
        const existingDoc = this.uploadedDocs.find(
          doc => doc.id && doc.id === d.id
        )
        return { ...d, ...(existingDoc || {}) }
      })
    },
    showMembership() {
      return (
        this.checkinInfo.show_membership === undefined ||
        this.checkinInfo.show_membership
      )
    },
    showSelfieId() {
      return (
        this.checkinInfo.show_selfie_with_id === undefined ||
        this.checkinInfo.show_selfie_with_id
      )
    },
    requiresSelfieId() {
      return (
        this.checkinInfo.require_selfie_with_id === undefined ||
        this.checkinInfo.require_selfie_with_id
      )
    },
  },
  watch: {
    dateMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  created() {
    this.clientInfo.email = this.preCheckIn.client_info.email || this.user.email
    if (
      this.clientInfo.email.includes('guests.quickconnect.rentals.com') ||
      this.clientInfo.email.includes('@boomguest.com')
    ) {
      this.clientInfo.email = ''
    }
    this.clientInfo.birthday =
      this.preCheckIn.client_info.birthday || this.user.birthday
    this.clientInfo.phone_number =
      this.preCheckIn.client_info.phone_number || this.user.phone
  },
  methods: {
    docExists(id) {
      return this.uploadedDocs.find(doc => doc.id && doc.id === id)
    },
    updateSignature(url) {
      this.termsSignature = url
      this.showTermsError = false
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    validate(autoStep = true) {
      this.showRequireVerificationDocsError =
        this.hasSuperhogVerification && (!this.documentId || !this.selfiePic)
      if (this.showRequireVerificationDocsError) return

      this.showUploadError = !this.documentId && this.requiresSelfieId
      this.showPhoneError = !this.phone.isValid
      this.showMissingDocsError = this.requestedDocuments.some(
        doc => !this.docExists(doc.id)
      )
      this.showTermsError =
        this.requireSignature &&
        !this.termsSignature &&
        !this.preCheckIn.terms_signature_image
      if (
        this.$refs.form.validate() &&
        (this.documentId || !this.requiresSelfieId) &&
        this.phone.isValid &&
        !this.showTermsError &&
        !this.showMissingDocsError
      ) {
        if (!autoStep) return true
        const payload = {
          client_info: this.clientInfo,
          request_to_memeber: this.requestRemember,
          vr_licence_signed: true,
          id_pic: this.documentId,
          selfie_pic: this.selfiePic,
          additional_doc: this.additionalDocumentId,
          terms_signature_image: this.termsSignature,
          additional_documents: this.uploadedDocs,
        }
        payload.client_info.phone_number = this.phone.formattedNumber
        this.$emit('step-forward', payload)
      }
    },
  },
}
</script>

<style scoped>
.v-stepper__content >>> .v-stepper__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashed-border {
  border-style: dashed;
}
.text-box,
.image-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contained-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>
